import React from 'react';
import classes from './Navbar.module.css';
import SimpleImage from '../SimpleImage/SimpleImage';

const NavbarTop = (props) => {

     const navClasses = [classes.nav];
     const navLinks = props.children ?
         props.children.map( (child, index) => {
              return <div className={classes.link} key={`link${index}`}>{child}</div>;
         }) : [];

    if(props.justifyLinksRight)
        navClasses.push(classes.justifyRight);

    return (


        <nav className={navClasses.join(' ')}>
            <input className={classes.hamburgerToggle} type="checkbox" id="hamburgerToggleBox"/>
            <label htmlFor="hamburgerToggleBox" className={classes.hamburgerIconContainer}>
                <span className={classes.hamburgerIcon}></span>
            </label>

            {   props.logo ?
                <div className={classes.logoBox}>
                    <SimpleImage height={props.logoHeight || '100%'} heightScale imageSource={props.logo}></SimpleImage>
                </div>

                : null
            }

            <div className={classes.linkContainer}>
                {navLinks}
            </div>
        </nav>
    );
};

export default NavbarTop;
