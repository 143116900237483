import React from 'react';
import classes from './Loader.module.css';

const loader = (props) => {
  return(
      <div className={classes.loaderWrapper}>
        <div className={classes.loader}
             style={
                 {
                     width: props.diameter || '50px',
                     height: props.diameter || '50px',
                     border: `${props.thickness || '10px'} solid ${props.mainColor || '#f0f0f0'}`,
                     'borderTopColor': `${props.accent || 'var(--accent)'}`
                 }
             }
        />
      </div>
  );
};

export default loader;