import React from 'react';
import classes from './Divider.module.css';

const Divider = (props) => {
    return (
        <div style={{height: props.height || '50px', paddingLeft: props.pad, paddingRight: props.pad}}>
            <div className={classes.divider} />
        </div>
    );
};

export default Divider;
