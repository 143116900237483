import React from 'react';
import classes from './Organizer.module.css';
import { isNativeElement, isReactFragment, wrapWithDiv } from "../util/ElementUtil";
import ratioClasses from '../AspectRatio.module.css';

const Card = require('../Card');
const MULTI_STYLE_DEFINITIONS = 'Organizer component may not contain children with both ' +
    'a "style" (inline-styles) and "styles" (parent pass-through styles) prop';

const getElementStyles = (element) => {
    const inlineStyles = element.props.style;
    const stylesFromParent = element.props.styles;
    let propName = '';
    let styles = {};

    if (inlineStyles && stylesFromParent){
        throw new Error(MULTI_STYLE_DEFINITIONS);
    }

    if (!inlineStyles && !stylesFromParent) {
        propName = isNativeElement(element) ? 'style' : 'styles';
    }
    else {
        propName = inlineStyles ? 'style' : 'styles';
        styles = inlineStyles ? inlineStyles : stylesFromParent;
    }

    return [styles, propName];
};

const calculateFlexBasis = (perRow) => {

    if (perRow <= 0)
        throw new Error('Unable to calculate Flex basis. perRow value must be greater than 0');

    // subtract 3 here to allow room for some space between elements
    return parseInt(100 / perRow) - 3 + '%';
};

// fill last row with empty cards if needed to keep alignment
const fillLastRow = (perRow, basis, elementArray) => {
    const remainder = elementArray.length % perRow;
    const cardsForPadding = [];

    if(remainder === 0)
        return elementArray;

    for(let i = 0; i < perRow - remainder; i++){
        cardsForPadding.push(<Card.PlaceholderCard key={`padCard${i}`} basis={basis}/>);
    }

    return [
        ...elementArray,
        ...cardsForPadding
    ];
};

const applyBasisStylesToChild = (element, basisStyles) => {
    const [styles, propName] = getElementStyles(element);
    return React.cloneElement(element, { [propName] : {...styles, ...basisStyles}});
};

const applyRatioAndBasisStyles = (element, basisStyles, ratio) => {
    const [styles, propName] = getElementStyles(element);

    //original child element of Orgnanizer will have height and width of 100% (ensure we stretch it to specified ratio)
    element = React.cloneElement(element, {[propName]: {...styles, 'height': '100%', 'width': '100%'}});

    //wrap original element with div that has ratio item class (ratioItem takes full width and height of div with ratio)
    element = wrapWithDiv(element, [ratioClasses.ratioItem]);

    //wrap ratioItem with div that has a ratio (i.e., ratio16x9)
    element = wrapWithDiv(element, [ratio]);

    //div with ratio calculates ratio using width of it's parent. Wrap div with ratio with div that has correct
    // basis and max-width
    element = wrapWithDiv(element, [], basisStyles);
    return element;
};

const getOrganizerClasses = (props) => {
    const organizerClasses = [classes.organizer];

    if(props.justifyEven){
        organizerClasses.push(classes.justifyEven);
    }

    return organizerClasses.join(' ');
};

const Organizer = (props) => {

    const basis = props.perRow ? calculateFlexBasis(props.perRow): props.basis;

    if(!basis)
        throw new Error('Unable to set basis. Organizer expects either perRow or basis prop');

    let basisStyles = { 'flexBasis' : basis, 'maxWidth': basis };

    let elements = React.Children.map(props.children, element => {
        if(typeof element !== 'object'){
            throw new Error('Organizer component may only contain React elements or HTML elements as children');
        }

        //TODO: wrap fragments in div so they're supported?
        if(isReactFragment(element)) {
            throw new Error('Immediate children of Organizer component may not be React Fragments');
        }

        if(props.ratio){
            return applyRatioAndBasisStyles(element, basisStyles, props.ratio);
        }

        return applyBasisStylesToChild(element, basisStyles);
    });

    if(!props.noPad)
        elements = fillLastRow(props.perRow, basis, elements);


    return(
        <div className={getOrganizerClasses(props)}>
            {elements}
        </div>
    );
};

export default Organizer;
