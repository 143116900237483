import React from 'react';
import {Route} from 'react-router-dom';
import NavbarTop from '../ReactUIComponents/Navbar/NavbarTop';
import MainContent from './MainContent/MainContent';
import Home from '../Components/Home/Home';
import Footer from '../Layout/Footer/Footer';
import logo from '../Assets/Logos/dragonRecolored.svg';
import SMIcon from "../ReactUIComponents/SMIcon/SMIcon";

const Layout = (props) => {
    return (
        <>
            <NavbarTop logo={logo}
                       logoHeight={'90%'}
                       justifyLinksRight
            >
                <SMIcon.Spotify size='25' link='https://tinyurl.com/REXBELESZASpotify'/>
                <SMIcon.Instagram size='25' link='https://www.instagram.com/rexbelesza/'/>
                <SMIcon.Twitter size='25' link='https://twitter.com/rexbelesza'/>
                <SMIcon.Youtube size='25' link='https://www.youtube.com/channel/UC2XZCCJKjdV6JDGgWZDt0Lg'/>
            </NavbarTop>

            <MainContent>
                <Route path='/' exact component={Home}></Route>
            </MainContent>
            <Footer/>
        </>
    );
};

export default Layout;

