import React from 'react';
import classes from './SimpleImage.module.css';
import Backdrop from '../Backdrop/Backdrop';


const simpleImage = (props) => {
    let overlay = <div className={classes.contentOverlay}>{props.children}</div>;
    return(
        <div
            className={classes.imageContainer}
            style={{'height': props.height, 'width': props.width, borderRadius: props.round ? '50%' : null}}
        >
            {props.backdrop ? <Backdrop>{overlay}</Backdrop> : overlay}
            <img
                style={{borderRadius: props.round ? '50%' : null}}
                className={props.heightScale ? classes.simpleImageHeightScale : classes.simpleImageWidthScale} src={props.imageSource}
                alt={props.alt}
            />
        </div>
    );
};

export default simpleImage;