import React from 'react';
import classes from "./LicenceBox.module.css";

const LicenceBox = (props) => {
  return (
      <div className={classes.box}>
          <div className={
              [
                  classes.licenseText,
                  classes.licenseType
              ]
                  .join(' ')}
          >
              {props.leaseType} LEASE
          </div>
          <div className={[classes.licenseText, classes.licencePrice].join(' ')}>${props.price}</div>
          <div className={classes.licenseText}>High Quality {props.leaseType} File</div>
          <div className={classes.licenseText}>Sell Up To {props.sellLimit} Copies</div>
          <div className={classes.licenseText}>Up To {props.streamLimit} Audio Streams MAX</div>
          <div className={classes.licenseText}>Download Instantly</div>
          <div className={classes.licenseText}>Full Licence Terms Available in Beat Player Above</div>
      </div>
  );
};

export default LicenceBox;
