import React from 'react';

export const isNativeElement = element => {
    return typeof element.type === 'string';
};

export const isReactFragment = element => {
  return typeof element['type'] === 'symbol' && Symbol.keyFor(element['type']) === 'react.fragment';
};

export const wrapWithDiv = (element, classes, styles) => {
    return <div className={classes.join(' ')} style={styles}>{element}</div>;
};

