import React from 'react';
import classes from './Home.module.css';
import LicenceBox from '../LicenceBox/LicenceBox';
import Organizer from '../../ReactUIComponents/Organizer/Organizer';
import BeatStore from '../BeatStore/BeatStore';
import SimpleImage from "../../ReactUIComponents/SimpleImage/SimpleImage";
import logo from '../../Assets/Logos/RBMonogram.svg';
import Divider from '../../ReactUIComponents/Divider/Divider';
import ratioClasses from '../../ReactUIComponents/AspectRatio.module.css';

const Home = (props) => {
    return (
        <>
            <div className={[classes.banner, ratioClasses.ratio16x9].join(' ')}>
                <div className={ratioClasses.ratioItem}>
                    <div className={classes.homeLogoBox}>
                        <SimpleImage width='50%' imageSource={logo}/>
                    </div>
                </div>
            </div>

            <Divider height='100px' pad='50px'/>

            <div className={classes.disclaimer}>
                Untagged Beat Immediately Available For Download After Purchase
                <br/>
                <span className={classes.noRefundText}>All sales are final. No refunds will be given under any circumstances. You are agreeing to these terms by making a purchase. </span>
            </div>
            <div className={classes.store}>
                <BeatStore/>
            </div>

            <Divider height='150px' pad='50px'/>

            <Organizer basis={'290px'} noPad justifyEven>
                <LicenceBox
                    leaseType={'MP3'}
                    price={'14.99'}
                    sellLimit={'2,500'}
                    streamLimit={'50,000'}
                />
                <LicenceBox
                    leaseType={'WAV'}
                    price={'19.99'}
                    sellLimit={'5,000'}
                    streamLimit={'100,000'}
                />
            </Organizer>
        </>
    );
};

export default Home;
