import React from 'react';
import classes from './Footer.module.css';
import SMIcon from '../../ReactUIComponents/SMIcon/SMIcon';
import SMIconTray from '../../ReactUIComponents/SMIconTray/SMIconTray';

const footer = (props) => {
    return (
        <div className={classes.footer}>
            <div className={classes.footerLargeText}>FOLLOW REX BELESZA</div>
            <SMIconTray>
                <SMIcon.Spotify size='25' link='https://tinyurl.com/REXBELESZASpotify'/>
                <SMIcon.Instagram size='25' link='https://www.instagram.com/rexbelesza/'/>
                <SMIcon.Twitter size='25' link='https://twitter.com/rexbelesza'/>
                <SMIcon.Youtube size='25' link='https://www.youtube.com/channel/UC2XZCCJKjdV6JDGgWZDt0Lg'/>
            </SMIconTray>
        </div>


    );
};

export default footer;
