import React from 'react';
import classes from './SimpleCard.module.css';
import SimpleImage from '../../SimpleImage/SimpleImage';
import {NavLink} from 'react-router-dom';

const cardType = 'SimpleCard';

const simpleCard = (props) => {
    const styles = {
        ...(props.styles ? props.styles : {}),
        ...(props.height ? {'height': props.height} : {})
    };

    return (
        <NavLink className={[classes.card, ...props.classes].join(' ')} to={props.navTo}
                 style={styles}>
            <div className={classes.imagePortion}>
                <SimpleImage imageSource={props.imgSrc} height="100%" alt={props.title}/>
            </div>
            <p className={classes.cardTitle}>{props.title}</p>
        </NavLink>
    );
};

export default simpleCard;
export {cardType};
