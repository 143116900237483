import React from 'react';
import classes from './SMIcon.module.css';
import './SMIcon.css';

const buildIcon = (type, size, link) => {
    const width = parseInt(size) + 10;
    return (
        <a className={['fa', type, classes.smIcon].join(' ')}
           style={{'fontSize': `${size}px`, 'padding': '5px', 'width': `${width}px`}}
           href={link}
           target='_blank'
           rel="noopener noreferrer"
        />
    )
};

const SMIcon = {
    Spotify: props => buildIcon('fa-spotify', props.size, props.link),
    Instagram: props => buildIcon('fa-instagram', props.size, props.link),
    Twitter: props => buildIcon('fa-twitter', props.size, props.link),
    Youtube: props => buildIcon('fa-youtube', props.size, props.link)
};

export default SMIcon;
