import React, {Component} from 'react';
import Loader from "../../ReactUIComponents/Loader/Loader";
import classes from './BeatStore.module.css';

class BeatStore extends Component {
    state = {
        loading: true
    };

    showStore = () => {
        this.setState({loading: false});
    };

    render() {
        const containerClasses = [classes.storeContainer, classes.storeHeight];
        const frameClasses = [classes.storeHeight];

        if(!this.state.loading)
            containerClasses.push(classes.storeShadow);
        else
            frameClasses.push(classes.hide);



        return (
            <div className={containerClasses.join(' ')}>
                {this.state.loading ? <Loader /> : null}

                <iframe title='Airbit' id="mfs_html5" src="https://airbit.com/widgets/html5/?uid=404752&config=813666" width="100%"
                        frameBorder="0" scrolling="no" onLoad={this.showStore} className={frameClasses.join(' ')}/>

            </div>
        );
    }
}

export default BeatStore;
